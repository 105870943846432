<script setup lang="ts">
    import { computed, ref } from 'vue';
    import type { LineItem, LineItemGroup } from '@containex/portal-backend-api-client';
    import { CheckoutDeliveryOption } from '@/checkout/model/checkout-delivery-option';
    import LineItemDisplay from '@/checkout/components/LineItemDisplay.vue';
    import { type TransportCostDto, TransportType, type TransportTypeCostDto } from '@containex/portal-backend-dto';
    import RadioCard from '@/components/form-components/RadioCard.vue';
    import { useI18n } from 'vue-i18n';
    import IconButtonWithOverlayPanel from '@/components/IconButtonWithOverlayPanel.vue';
    import { sleep } from '@/util/sleep';
    import { useAsyncTask } from 'vue-concurrency';
    import { useMarketQuery } from '@/composables/market';
    import { useCheckoutAction, useCheckoutQuery } from '@/checkout/composables/checkout';
    import Select from 'primevue/select';
    import { lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts } from '@/util/line-items';

    const props = defineProps<{
        group: LineItemGroup;
        items: LineItem[];
        transportCost: TransportCostDto;
        option: CheckoutDeliveryOption;
    }>();

    const emits = defineEmits<{
        'update:option': [CheckoutDeliveryOption];
        'update:group': [LineItemGroup];
        'update-group-and-option': [LineItemGroup, CheckoutDeliveryOption];
    }>();

    const { t } = useI18n();
    const { market } = useMarketQuery();
    const { rentalDepots } = useCheckoutQuery();
    const checkoutAction = useCheckoutAction();

    const craneTruckOption = computed(() =>
        props.transportCost.transportTypeCosts?.find(
            (option: TransportTypeCostDto) => option.transportType === TransportType.CraneTruck
        )
    );
    const pickupOption = computed(() =>
        props.transportCost.transportTypeCosts?.find(
            (option: TransportTypeCostDto) => option.transportType === TransportType.PickUp
        )
    );
    const truckOption = computed(() =>
        props.transportCost.transportTypeCosts?.find(
            (option: TransportTypeCostDto) => option.transportType === TransportType.Truck
        )
    );

    const filteredLineItems = computed(() =>
        lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts(props.items)
    );

    // this method gets called multiple times on changes, thus the hack
    // with sleep and useAyncTask to reduce its invocations
    const setTransportType = useAsyncTask(async (signal, method: TransportType) => {
        await sleep(0);

        const transportOption = getTransportOptionForMethod(method);
        const customGroup: LineItemGroup = {
            ...props.group,
            transport_type: method,
            transport_price: transportOption?.price ?? 0,
        };

        if (method === TransportType.PickUp) {
            emits('update-group-and-option', customGroup, CheckoutDeliveryOption.Custom);
        } else {
            emits('update:group', customGroup);
        }

        if (method === TransportType.PickUp) {
            await fetchRentalDepots.perform();
        }
    }).drop();

    function getTransportOptionForMethod(method: TransportType): TransportTypeCostDto | undefined {
        switch (method) {
            case TransportType.CraneTruck:
                return craneTruckOption.value;
            case TransportType.Truck:
                return truckOption.value;
            case TransportType.PickUp:
                return pickupOption.value;
        }
    }

    const selectedRentalDepot = ref();

    const fetchRentalDepots = useAsyncTask(async (): Promise<void> => {
        await checkoutAction.fetchRentalDepots();
        if (rentalDepots.value.some((depot) => depot.id === props.group.depot.depot_id)) {
            selectedRentalDepot.value = props.group.depot.depot_id;
        }
    });

    if (props.group.transport_type === TransportType.PickUp) {
        void fetchRentalDepots.perform();
    }

    const updateRentalPickUpDepot = useAsyncTask(async (signal, depotId: string) => {
        await checkoutAction.updateLineItemGroupDepot(props.group.id, depotId);
    });
</script>

<template>
    <div class="edit-content">
        <div class="line-items">
            <LineItemDisplay
                v-for="lineItem of filteredLineItems"
                :key="lineItem.id"
                :line-item="lineItem"
            ></LineItemDisplay>
        </div>
        <div class="options">
            <div class="delivery-options">
                <div v-if="craneTruckOption" class="delivery-option-wrapper">
                    <RadioCard
                        name="transportType"
                        :model-value="group.transport_type"
                        :value="TransportType.CraneTruck"
                        @update:model-value="setTransportType.perform(TransportType.CraneTruck)"
                    >
                        <template #header>
                            <div class="radio-card-header">
                                <span class="title">{{ t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.TITLE') }}</span>
                                <IconButtonWithOverlayPanel class="info-icon">
                                    <template #icon>
                                        <i class="pi pi-info-circle" />
                                    </template>
                                    <template #overlay-panel-content>
                                        <div>{{ t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.INFO') }}</div>
                                    </template>
                                </IconButtonWithOverlayPanel>
                            </div>
                        </template>
                        <template v-if="group.transport_type === TransportType.CraneTruck" #content>
                            <div class="delivery-method-description">
                                {{ t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.DETAILS') }}
                            </div>
                        </template>
                    </RadioCard>
                </div>
                <div v-if="truckOption" class="delivery-option-wrapper">
                    <RadioCard
                        name="transportType"
                        :model-value="group.transport_type"
                        :value="TransportType.Truck"
                        @update:model-value="setTransportType.perform(TransportType.Truck)"
                    >
                        <template #header>
                            <div class="radio-card-header">
                                <span class="title">{{ t('CART.STEPS.DELIVERY.METHOD.TRUCK.TITLE') }}</span>
                                <IconButtonWithOverlayPanel class="info-icon">
                                    <template #icon>
                                        <i class="pi pi-info-circle" />
                                    </template>
                                    <template #overlay-panel-content>
                                        <div>{{ t('CART.STEPS.DELIVERY.METHOD.TRUCK.INFO') }}</div>
                                    </template>
                                </IconButtonWithOverlayPanel>
                            </div>
                        </template>
                    </RadioCard>
                </div>
                <div v-if="pickupOption" class="delivery-option-wrapper">
                    <RadioCard
                        name="transportType"
                        :model-value="group.transport_type"
                        :value="TransportType.PickUp"
                        @update:model-value="setTransportType.perform(TransportType.PickUp)"
                    >
                        <template #header>
                            <div class="radio-card-header">
                                <span class="title">{{ t('CART.STEPS.DELIVERY.METHOD.PICK_UP.TITLE') }}</span>
                                <IconButtonWithOverlayPanel class="info-icon">
                                    <template #icon>
                                        <i class="pi pi-info-circle" />
                                    </template>
                                    <template #overlay-panel-content>
                                        <div>{{ t('CART.STEPS.DELIVERY.METHOD.PICK_UP.INFO') }}</div>
                                    </template>
                                </IconButtonWithOverlayPanel>
                            </div>
                        </template>
                        <template v-if="group.transport_type === TransportType.PickUp" #content>
                            <div class="delivery-method-description">
                                {{ t('CART.STEPS.DELIVERY.METHOD.PICK_UP.DETAILS') }}
                            </div>
                        </template>
                    </RadioCard>
                </div>
            </div>
            <div v-if="group.transport_type === TransportType.PickUp" class="select-depot-container">
                <div class="select-depot-title">
                    <i class="pi pi-building" />
                    <div class="text-base-bold-line-height-auto">
                        {{ t('CART.STEPS.DELIVERY.SELECT_DEPOT_TITLE') }}
                    </div>
                </div>
                <div class="rental-depot-dropdown-container">
                    <Select
                        v-model="selectedRentalDepot"
                        :options="rentalDepots"
                        :option-label="(depot) => `${market?.code}-${depot.postal_code} ${depot.city}`"
                        option-value="id"
                        filter
                        :disabled="fetchRentalDepots.isRunning"
                        :loading="fetchRentalDepots.isRunning"
                        :placeholder="t('CART.STEPS.DELIVERY.SELECT_DEPOT_TITLE')"
                        class="rental-depot-dropdown"
                        @update:model-value="(depotId: string) => updateRentalPickUpDepot.perform(depotId)"
                    />
                </div>
            </div>
            <div v-else>
                <slot name="shipping-address"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .edit-content {
        display: flex;
        gap: main.$spacing-5;
        border: 1px solid main.$color-border-gray;
        border-radius: 4px;
        padding: main.$spacing-5;
    }

    .delivery-method-description {
        margin-top: main.$spacing-3;
    }

    .delivery-option-wrapper {
        position: relative;
    }

    .delivery-cost {
        flex-shrink: 0;
    }

    .line-items {
        flex: 1;
    }

    .options {
        flex: 2;
    }

    .radio-card-header {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: main.$spacing-5;
        font-weight: 500;

        .title {
            flex-grow: 1;
            hyphens: auto;
        }
    }

    .button-group-delivery {
        display: inline-flex;
        flex-wrap: nowrap;
        hyphens: auto;
    }

    .info-icon {
        color: main.$color-primary-500;

        &:hover {
            border: 0 none;
            border-radius: 50%;
            background-color: main.$color-background-bluegray;
        }
    }

    .delivery-date {
        color: main.$color-green-dark;
        font-size: main.$font-size-2;
    }

    .week-picker-width {
        width: auto;
    }

    .p-button {
        color: main.$toggle-button-active;
        background-color: main.$color-background-lightblue;
        border-color: main.$toggle-button-border;
        font-weight: 500;
    }

    .p-button-outlined {
        color: main.$color-text;
        background-color: main.$color-white;
        font-weight: 500;
    }

    .bold-panel-title {
        font-weight: bold;
    }

    @media (width <= 767px) {
        .edit-content {
            flex-direction: column;
        }

        .line-items,
        .options {
            width: 100%;
        }

        .options {
            margin-top: main.$spacing-3;
        }

        .p-button {
            padding-left: main.$spacing-3;
            padding-right: main.$spacing-3;
        }

        .delivery-cost {
            flex-shrink: 1;
        }
    }

    :deep(.radio-card-container) {
        font-weight: 400;
    }

    .select-depot-container {
        padding-top: main.$spacing-6;
    }

    .select-depot-title {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
    }

    .rental-depot-dropdown-container {
        padding-top: main.$spacing-4;
    }

    .rental-depot-dropdown {
        width: 50%;
        min-width: 280px;
    }
</style>
