<script setup lang="ts">
    import { computed, ref } from 'vue';
    import ShippingAddressSelection from '@/checkout/components/ShippingAddressSelection.vue';
    import { ShippingAddressOption } from '@/types/ShippingAddressOption';
    import { type AddressDto, DeliveryTime, TransportType } from '@containex/portal-backend-dto';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { useCompanyQuery } from '@/composables/company';
    import { areAddressesEqual, isEnteredAddressEmpty, isMedusaAddressValid } from '@/util/addressHelpers';
    import { medusaAddressToAddressDto } from '@containex/portal-medusa-mapper';
    import { useCheckoutAction, useCheckoutQuery } from '@/checkout/composables/checkout';
    import CheckoutSectionCard from '@/checkout/components/common/CheckoutSectionCard.vue';
    import type { LineItem, LineItemGroup } from '@containex/portal-backend-api-client';
    import {
        useCheckoutDeliveryOptionAction,
        useCheckoutDeliveryOptionQuery,
    } from '@/checkout/composables/checkout-delivery-option';
    import LineItemGroupEdit from '@/checkout/components/rental/LineItemGroupEdit.vue';
    import { useAsyncTask } from 'vue-concurrency';
    import { CheckoutDeliveryOption } from '@/checkout/model/checkout-delivery-option';
    import BlockUI from 'primevue/blockui';
    import { useMarketAction } from '@/composables/market';
    import { useI18n } from 'vue-i18n';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';

    const { t } = useI18n();
    const checkoutAction = useCheckoutAction();
    const companyQuery = useCompanyQuery();
    const { cart, currentZipCode } = useCartQuery();
    const cartAction = useCartAction();
    const checkoutDeliveryOptionAction = useCheckoutDeliveryOptionAction();
    const checkoutDeliveryOptionQuery = useCheckoutDeliveryOptionQuery();
    const marketAction = useMarketAction();
    const { transportCosts } = useCheckoutQuery();

    const defaultShippingAddress = companyQuery.company.value?.deliveryAddress;
    const cartShippingAddress = cart.value?.shipping_address;
    const cartId = cart.value?.id ?? '';
    const shippingAddressOption = ref(ShippingAddressOption.DEFAULT);
    const differentShippingAddress = ref<AddressDto>({});

    const data = computed(() => {
        const lineItems: LineItem[] = cart.value?.items ?? [];
        const group = cart.value?.lineItemGroups[0];
        if (group == null) {
            return null;
        }

        const transportOption = transportCosts.value.find(
            (transportCost) => transportCost.lineItemGroupId === group.id
        );

        if (lineItems.length === 0 || transportOption == null) {
            return null;
        }

        return {
            items: lineItems,
            group,
            option: checkoutDeliveryOptionQuery.forLineItemGroupId(group.id).value,
            transportOption,
        };
    });

    const transportCostTask = useAsyncTask(async () => {
        await checkoutAction.initLineItemGroups();
        await checkoutAction.calculateTransportCost(cartId);
        await cartAction.initCartStore();
    }).restartable();

    const initTask = useAsyncTask(async () => {
        // determine selected address and address option
        if (cartShippingAddress == null || !isMedusaAddressValid(cartShippingAddress)) {
            differentShippingAddress.value.postalCode = currentZipCode.value;

            if (currentZipCode.value === defaultShippingAddress?.postalCode) {
                await selectDefaultAddress();
            } else {
                shippingAddressOption.value = ShippingAddressOption.CUSTOM;
            }
        } else if (
            defaultShippingAddress != null &&
            isMedusaAddressValid(cartShippingAddress) &&
            areAddressesEqual(medusaAddressToAddressDto(cartShippingAddress), defaultShippingAddress)
        ) {
            differentShippingAddress.value.postalCode = currentZipCode.value;

            await selectDefaultAddress();
        } else {
            await selectDifferentAddress(medusaAddressToAddressDto(cartShippingAddress));
        }

        transportCostTask.perform();
    });

    const handleUpdateDifferentShippingAddressTask = useAsyncTask(async (signal, updatedAddress: AddressDto) => {
        switch (shippingAddressOption.value) {
            case ShippingAddressOption.CUSTOM:
                differentShippingAddress.value = updatedAddress;
                break;
            case ShippingAddressOption.DEFAULT:
                break;
        }

        if (!isEnteredAddressEmpty(updatedAddress) && updatedAddress.postalCode != null) {
            const regionId = await marketAction.getRegionByZipCode(updatedAddress.postalCode);
            await checkoutAction.updateCartShippingAddress(updatedAddress, regionId);
        } else {
            await cartAction.updateRegionBasedOnZipCodeAndRentalDuration(
                updatedAddress.postalCode ?? '',
                undefined,
                undefined
            );
        }
    });

    const updateGroupTask = useAsyncTask(async (signal, group: LineItemGroup) => {
        await checkoutAction.updateLineItemGroup(
            {
                id: group.id,
                deliveryTime: group.delivery_time,
                transportType: group.transport_type,
                estimatedDeliveryDateStart: group.estimated_delivery_date_start,
                estimatedDeliveryDateEnd: group.estimated_delivery_date_end,
            },
            group.transport_price
        );

        if (group.transport_type !== TransportType.PickUp) {
            await transportCostTask.perform();
        }
    });

    void initTask.perform();

    async function selectDefaultAddress(): Promise<void> {
        shippingAddressOption.value = ShippingAddressOption.DEFAULT;

        if (defaultShippingAddress != null) {
            await handleUpdateDifferentShippingAddressTask.perform(defaultShippingAddress);
            await transportCostTask.perform();
        }
    }

    async function selectDifferentAddress(address: AddressDto): Promise<void> {
        shippingAddressOption.value = ShippingAddressOption.CUSTOM;
        differentShippingAddress.value = address;
        await handleUpdateDifferentShippingAddressTask.perform(address);
        await transportCostTask.perform();
    }

    async function updateGroupOption(group: LineItemGroup, option: CheckoutDeliveryOption): Promise<void> {
        const shouldReset = shouldResetDatesToInitial(option);

        checkoutDeliveryOptionAction.updateForLineItemGroup(group.id, option);

        await updateGroupTask.perform({
            ...group,
            delivery_time: getDeliveryTimeForCheckoutDeliveryOption(option),
            estimated_delivery_date_start: shouldReset ? undefined : group.estimated_delivery_date_start,
            estimated_delivery_date_end: shouldReset ? undefined : group.estimated_delivery_date_end,
        });
    }

    function getDeliveryTimeForCheckoutDeliveryOption(option: CheckoutDeliveryOption): DeliveryTime {
        switch (option) {
            case CheckoutDeliveryOption.AsSoonAsPossible:
                return DeliveryTime.Asap;
            case CheckoutDeliveryOption.Custom:
                return DeliveryTime.Custom;
        }
    }

    function shouldResetDatesToInitial(option: CheckoutDeliveryOption): boolean {
        switch (option) {
            case CheckoutDeliveryOption.Custom:
                return false;
            case CheckoutDeliveryOption.AsSoonAsPossible:
                return true;
        }
    }
</script>

<template>
    <div v-if="initTask.isIdle" class="step-wrapper">
        <BlockUI :blocked="transportCostTask.isRunning || updateGroupTask.isRunning">
            <CheckoutSectionCard>
                <template #header>{{ t('CART.STEPS.DELIVERY.TITLE') }}</template>
                <template #content>
                    <span>{{ t('CART.STEPS.DELIVERY.DESCRIPTION') }}</span>
                    <LineItemGroupEdit
                        v-if="data != null"
                        :key="data.group.id"
                        :items="data.items"
                        :group="data.group"
                        :option="data.option"
                        :transport-cost="data.transportOption"
                        @update:option="updateGroupOption(data.group, $event)"
                        @update:group="(group) => updateGroupTask.perform(group)"
                        @update-group-and-option="updateGroupOption"
                    >
                        <template #shipping-address>
                            <ShippingAddressSelection
                                v-if="defaultShippingAddress != null"
                                class="shipping-address-spacing"
                                :shipping-address-option="shippingAddressOption"
                                :default-shipping-address="defaultShippingAddress"
                                :different-shipping-address="differentShippingAddress"
                                :blocked="handleUpdateDifferentShippingAddressTask.isRunning"
                                :is-rental="true"
                                @select-default-address="selectDefaultAddress"
                                @select-different-address="selectDifferentAddress"
                            />
                        </template>
                    </LineItemGroupEdit>
                </template>
            </CheckoutSectionCard>
        </BlockUI>
    </div>
    <LoadingSpinner v-else-if="initTask.isRunning" class="loading-spinner-positioning" />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .shipping-address-spacing {
        padding-top: main.$spacing-6;
    }

    :deep(.shipping-address-spacing .checkout-section-card) {
        padding: 0;
    }

    :deep(.shipping-address-spacing h3) {
        padding: 0;
    }

    .step-wrapper {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .panel-content {
        display: flex;
        gap: main.$spacing-5;
    }

    .line-items {
        flex: 1;
    }

    .delivery-date {
        color: main.$color-green-dark;
        font-size: main.$font-size-2;
    }

    .loading-spinner-positioning {
        width: 100%;
    }
</style>
